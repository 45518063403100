import { getCoverImage, getLastMessage } from "../../../functions/chat";
import { store } from "../../../redux/stores/store";
import {
  AvatarContainer,
  AvatarStyled,
  ChatRowContainer,
  CountBadgeContainer,
  PrimaryText,
  SecondaryText,
  TextContainer,
} from "./styles";

function ChatRow(props) {
  function triggerChatClick() {
    props.onChatClicked(props.chat);
  }

  return (
    <ChatRowContainer onClick={triggerChatClick}>
      <AvatarContainer>
        <AvatarStyled
          src={getCoverImage(props.chat, store.getState().user.isAiviSessions)}
        />
        {props.chat.unreadMessageCount > 0 && (
          <CountBadgeContainer>
            {props.chat.unreadMessageCount}
          </CountBadgeContainer>
        )}
      </AvatarContainer>
      <TextContainer>
        <PrimaryText component="span" noWrap>
          {props.chat.name}
        </PrimaryText>
        <SecondaryText component="span" noWrap>
          {getLastMessage(props.chat)}
        </SecondaryText>
      </TextContainer>
    </ChatRowContainer>
  );
}

export default ChatRow;
