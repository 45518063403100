import SendBird from "sendbird";

class Sendbird {
  constructor(appId) {
    this.token = process.env.SENDBIRD_APP_ID;
    this.api_token = process.env.SENDBIRD_API_TOKEN;
    this.sb = new SendBird({ appId: appId });
    this.channelListQuery = null;
    this.closedChannelListQuery = null;
    this.previousMessageQuery = null;
    this.currentChannelUrl = "";
  }

  getInstance() {
    return this.sb;
  }

  /*Connect to sendbird*/
  connect(user) {
    return new Promise((resolve, reject) => {
      try {
        this.sb.connect(user.id, (userInfo, error) => {
          if (error) {
            reject("SendBird Login Failed.");
          } else {
            resolve(this.updateUserinfo(user));
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      try {
        this.sb.disconnect(() => {
          resolve(true);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  getConnectionStatus() {
    if (this.sb) {
      return this.sb.getConnectionState();
    }
  }

  /*update user*/
  updateUserinfo(user) {
    return new Promise((resolve, reject) => {
      const imgUrl = user.profile_url
        ? user.profile_url
        : "//assets.wobbjobs.com/images/shared/default-profile-picture.png";
      this.sb.updateCurrentUserInfo(
        user.name,
        `https:${imgUrl}`,
        (user, error) => {
          if (error) {
            reject("Update profile failed.");
          } else {
            resolve(user);
          }
        }
      );
    });
  }

  /*
  ----------========================== Message Function ==========================----------
  */

  /*
   * get message
   * params:
   * 1)channel
   * 2)refresh
   */
  getMessageList(params = {}) {
    if (params.refresh || this.previousMessageQuery === null) {
      this.previousMessageQuery =
        params.channel.createPreviousMessageListQuery();
    }
    return new Promise((resolve, reject) => {
      if (
        this.previousMessageQuery.hasMore &&
        !this.previousMessageQuery.isLoading
      ) {
        this.previousMessageQuery.load(100, false, (messageList, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(messageList);
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  // send message
  sendMessage(channel, textMessage) {
    return new Promise((resolve, reject) => {
      channel.sendUserMessage(textMessage, (message, error) => {
        if (error) {
          reject(error);
        } else {
          this.markAsRead({ channel });
          resolve(message);
        }
      });
    });
  }
  /*
  ----------========================== Group Channel Function ==========================----------
  */

  /*
   * get List Channel
   * params:
   * 1)customType
   * 2)refresh
   * 3)isOpen
   */
  getGroupChannelList(params = {}) {
    return new Promise((resolve, reject) => {
      if (params.isOpen) {
        //open chat
        if (params.refresh || this.channelListQuery == null) {
          this.channelListQuery =
            this.sb.GroupChannel.createMyGroupChannelListQuery();
        }

        this.channelListQuery.includeEmpty = true;
        this.channelListQuery.limit = 50;
        this.channelListQuery.order = "latest_last_message";

        if (params.customType != null) {
          this.channelListQuery.customTypesFilter = [...params.customType];
        }

        if (this.channelListQuery.hasNext && !this.channelListQuery.isLoading) {
          this.channelListQuery.next((channelList, error) => {
            if (error) {
              reject(error);
            } else {
              resolve(channelList);
            }
          });
        }
      } else {
        //closed chat
        if (params.refresh || this.closedChannelListQuery == null) {
          this.closedChannelListQuery =
            this.sb.GroupChannel.createMyGroupChannelListQuery();
        }

        this.closedChannelListQuery.includeEmpty = true;
        this.closedChannelListQuery.limit = 50;
        this.closedChannelListQuery.order = "latest_last_message";

        if (params.customType != null) {
          this.closedChannelListQuery.customTypesFilter = [
            ...params.customType,
          ];
        }

        if (
          this.closedChannelListQuery.hasNext &&
          !this.closedChannelListQuery.isLoading
        ) {
          this.closedChannelListQuery.next((channelList, error) => {
            if (error) {
              reject(error);
            } else {
              resolve(channelList);
            }
          });
        }
      }
    });
  }

  // get list of group channels with channel name
  getGroupChannelListWithChannelNameAndCompanyName(
    keyword,
    custom_type,
    searchType
  ) {
    return new Promise((resolve, reject) => {
      var myChannelListQuery =
        this.sb.GroupChannel.createMyGroupChannelListQuery();
      myChannelListQuery.includeEmpty = true;

      if (searchType) myChannelListQuery.nicknameContainsFilter = keyword;
      else myChannelListQuery.channelNameContainsFilter = keyword;

      myChannelListQuery.limit = 50;
      myChannelListQuery.customTypesFilter = [custom_type];
      myChannelListQuery.metadata_key = "company_name";
      myChannelListQuery.metadata_values = encodeURI(keyword);

      myChannelListQuery.next((channels, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(channels);
        }
      });
    });
  }

  // get detail of channel
  getChannelInfo(channelUrl) {
    return new Promise((resolve, reject) => {
      this.sb.GroupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(channel);
        }
      });
    });
  }

  //channel list handler
  /*
   * Params:
   * 1) uniqueId
   * 2) updateMessageList - redux action
   * 3) updateChannel - redux action
   * 4) countUnreadMessageWithException - redux action
   */
  createChannelListHandler(params = {}) {
    const channelHandler = new this.sb.ChannelHandler();

    channelHandler.onMessageReceived = (channel, message) => {
      params.updateMessageList(channel, message);
    };

    channelHandler.onReadReceiptUpdated = (channel) => {
      if (channel.url == this.currentChannelUrl) {
        setTimeout(() => {
          //This does not guarantee to update unread count properly
          params.updateCurrentChat(channel);
        }, 500);
      }
    };

    // on user left the channel
    channelHandler.onUserLeft = (channel, user) => {
      params.updateChannel(channel, false);
    };

    this.sb.addChannelHandler(params.uniqueId, channelHandler);
  }

  getChannelMetaData = async (channel) => {
    const metaData = [
      "chat_status",
      "cb_session",
      "cb_status",
      "cb_last_context",
      "job_enable_chatbot",
      "company_name",
    ];

    return new Promise((resolve, reject) => {
      channel.getMetaData(metaData, (data, error) => {
        if (error) {
          reject(error);
        } else {
          if (data.cb_session) {
            resolve(data);
          }
        }
      });
    });
  };

  //Mark as Read channel
  markAsRead({ channelUrl, channel }) {
    if (channel) {
      channel.markAsRead(() => {
        //
      });
    } else {
      this.getChannelInfo(channelUrl).then((channel) =>
        channel.markAsRead(() => {
          //done marking as read
        })
      );
    }
  }

  //remove channel handler
  removeChannelHandler(channelHandlerId) {
    this.sb.removeChannelHandler(channelHandlerId);
  }

  updateChannelUrl(url) {
    this.currentChannelUrl = url;
  }
}
export { Sendbird as default };
