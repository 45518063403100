import { ButtonBase } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import MYFlag from "../../../public/images/MY.svg";
import SGFlag from "../../../public/images/SG.svg";

export const GridChat = styled(Grid)`
  && {
    height: 100%;
  }
`;

export const ChatContainer = styled(Box)`
  && {
    position: fixed;
    bottom: 4%;
    right: 2%;
    z-index: 10;
  }
`;

export const ChatContentWrapper = withTheme(styled.div`
  && {
    border-radius: 1rem;
    position: absolute;
    bottom: 4.25rem;
    right: 0;
    width: 360px;
    height: 448px;
    background-color: ${Color.white};
    display: ${(props) => (props.$show_chatbox ? "block" : "none")};
    box-shadow: 0px 0px 5px 2px rgba(182, 162, 162, 0.75);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(182, 162, 162, 0.75);
    -moz-box-shadow: 0px 0px 5px 2px rgba(182, 162, 162, 0.75);
  }
`);

export const ChatHeader = styled.div`
  && {
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.75rem;
    margin-top: 0.5rem;
  }
`;

export const SearchFieldContainer = styled.div`
  padding: 15px 15px 15px 15px;
  width: 100%;
  border-bottom: 1px solid lightgray;
`;

export const HeaderText = styled(Typography)`
  && {
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  && {
    color: ${Color.black};
  }
`;

export const SearchTextField = styled(TextField)`
  && {
    width: 100%;
  }
`;

export const GridStyled = styling(Grid)({
  //
});

export const MYCountryFlag = styling(MYFlag)({
  width: "24px",
  height: "auto",
  objectFit: "contain",
});

export const SGCountryFlag = styling(SGFlag)({
  width: "24px",
  height: "auto",
  objectFit: "contain",
});

export const CountryButton = styling(ButtonBase)((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  flex: 1,

  color: Color.darkPurple,
  background: props.selected && Color.lightPurple,

  fontFamily: "Inter",
  fontSize: "0.75rem",
  lineHeight: "1.25rem",
  textTransform: "none",

  borderRadius: "0.375rem",
  border: props.selected
    ? "1.5px solid" + Color.darkPurple
    : "1px solid rgba(0, 0, 0, 0.87)",

  height: "2.75rem",
  padding: "0 1.5rem",

  transition: "0.125s ease-in-out",

  [props.theme.breakpoints.up("lg")]: {
    "&:hover": {
      backgroundColor: Color.lightPurple,
      border: "1.5px solid" + Color.darkPurple,
    },
  },

  // [props.theme.breakpoints.down("md")]: {
  //   padding: "0 2.911rem",
  // },

  // [props.theme.breakpoints.down("sm")]: {
  //   padding: "0",
  //   width: "100%",
  // },
}));
