import * as config from "../config/config";

const getCoverImage = (chat, aiviSessions) => {
  let imageUrl;

  if (aiviSessions[chat.url]) {
    imageUrl =
      config.assetDomain + "/images/employers/product_icons/chatbot_token.png";
  } else if (chat.coverUrl === "https:") {
    imageUrl =
      config.assetDomain + "/images/shared/default-profile-picture.png";
  } else {
    imageUrl = chat.coverUrl;
  }

  if (imageUrl === "https:null") {
    imageUrl =
      config.assetDomain + "/images/shared/default-profile-picture.png";
  }

  return imageUrl;
};

const getLastMessage = (chat) => {
  if (chat.lastMessage && chat.lastMessage.message) {
    return chat.lastMessage.message;
  }

  return "";
};

export { getLastMessage, getCoverImage };
