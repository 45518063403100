import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Linkify from "react-linkify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatDate, isAiviSession } from "../../../helpers/data_management";
import {
  countUnreadMessage,
  fetchChatMessages,
  getUser,
  sendMessage,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import {
  AiviDisclaimerTextContainer,
  AiviSessionContainer,
  ArrowBackIosStyled,
  ChatRoomContainer,
  ChatRoomHeaderContainer,
  GridProgress,
  LabelStyled,
  MessageContainer,
  MessageContent,
  MessageInput,
  MessageText,
  MessageTextCreated,
  MessageWrapper,
  MessagesContainer,
  PrimaryText,
  RetryContainer,
  SecondaryText,
  SendButton,
  SendIconStyled,
  SendMessageContainer,
  TextContainer,
  WarningIconStyled,
} from "./styles";

const ChatRoom = forwardRef((props, ref) => {
  const [messageValue, setMessageValue] = useState("");
  const [doneLoadingMessage, setDoneLoadingMessage] = useState(false);
  const [sbConnected, setSbConnected] = useState(false);
  const [poorNetwork, setPoorNetwork] = useState(false);
  const lastMessageRef = useRef(null);

  function getChatRoomSbStatus() {
    return sbConnected;
  }

  // To allow parent to call this function
  useImperativeHandle(ref, () => ({
    setSbConnected,
    setPoorNetwork,
    getChatRoomSbStatus,
  }));

  useEffect(() => {
    if (props.currentChat && props.currentChat.createdAt) {
      getChatMessages(true, props.currentChat);
    }
  }, [props.currentChat]);

  useEffect(() => {
    return () => {
      store.getState().user.currentChatUrl = "";
    };
  }, []);

  useEffect(() => {
    if (lastMessageRef && lastMessageRef.current) {
      setTimeout(() => {
        if (lastMessageRef && lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView();
        }
      }, 200); // 200 milliseconds to scroll to bottom after new message is rendered
    }
  }, [props.chatMessages]);

  function getChatMessages(refresh = false, currentChat) {
    let params = {
      sb: props.sb,
      channel: currentChat,
      refresh: refresh,
    };

    props.restartRetryTimer(true); // Getting messages, start poor network timer

    if (params.channel && params.channel.createdAt) {
      props.fetchChatMessages(params).then((response) => {
        props.clearRetryTimer(); // Done loading messages, clear poor network timer
        setDoneLoadingMessage(true);
        setSbConnected(true);
        // Mark message as read for this channel
        let cParams = {
          channel: props.currentChat,
        };
        props.sb.markAsRead(cParams);
        setTimeout(() => {
          props.countUnreadMessage(); // Recompute total unread message count
        }, 500);
      });
    }
  }

  function getCompanyName(currentChat) {
    if (currentChat && currentChat.createdAt) {
      if (currentChat.data) {
        return currentChat.data;
      }
    }

    return "";
  }

  function getJobTitle(currentChat) {
    if (currentChat && currentChat.createdAt) {
      if (currentChat.name) {
        return currentChat.name;
      }
    }

    return "";
  }

  function isUserMessage(message) {
    let user = getUser();

    if (
      message._sender &&
      message._sender.userId === user.id &&
      message.messageType !== "admin"
    ) {
      return true;
    }

    return false;
  }

  function onTriggerSendMessage() {
    if (messageValue == "") return;

    let params = {
      sb: props.sb,
      channel: store.getState().user.currentChat,
      textMessage: messageValue,
    };

    setMessageValue("");

    if (params.channel && params.channel.createdAt) {
      props.sendMessage(params);
    }
  }

  function onMessageChange(event) {
    setMessageValue(event.target.value.substring(0, 500));
    if (props.resetIdleTimer) {
      props.resetIdleTimer();
    }
  }

  function onKeyDown(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      onTriggerSendMessage();
    }
  }

  const aiviActive = isAiviSession(props.currentChat);

  // Need to use the same parent param for this case
  function retrySendbird() {
    // Need set poor network back to false
    setPoorNetwork(false);
    setSbConnected(false);
    props.updateSendbirdConnectionCount(0);
    props.connectSendBird(true);
  }

  return (
    <ChatRoomContainer>
      <ChatRoomHeaderContainer>
        <IconButton
          aria-label="close"
          onClick={() => props.updateSingleChatStatus(false)}
          size="small"
        >
          <ArrowBackIosStyled />
        </IconButton>
        <TextContainer>
          <PrimaryText noWrap component="span">
            {getCompanyName(props.currentChat)}
          </PrimaryText>
          <SecondaryText noWrap component="span">
            {getJobTitle(props.currentChat)}
          </SecondaryText>
        </TextContainer>
      </ChatRoomHeaderContainer>
      {aiviActive && (
        <AiviSessionContainer>
          <WarningIconStyled />
          <AiviDisclaimerTextContainer>
            <SecondaryText component="span">
              Virtual interviewer is active.
            </SecondaryText>
            <SecondaryText component="span">
              Please send your answers in one message.
            </SecondaryText>
          </AiviDisclaimerTextContainer>
        </AiviSessionContainer>
      )}
      <MessagesContainer>
        {!doneLoadingMessage || !sbConnected || poorNetwork ? (
          <Fragment>
            {poorNetwork ? (
              <RetryContainer>
                <LabelStyled>
                  Poor network connection, please click on the button to retry.
                </LabelStyled>
                <Button button_type={"SolidGrey"} onClick={retrySendbird}>
                  Retry
                </Button>
              </RetryContainer>
            ) : (
              <GridProgress>
                <CircularProgress />
              </GridProgress>
            )}
          </Fragment>
        ) : (
          props.chatMessages.map((message, index) => {
            return (
              <MessageContainer key={index}>
                <MessageWrapper $isUserMessage={isUserMessage(message)}>
                  <MessageContent $isUserMessage={isUserMessage(message)}>
                    <MessageText component="span">
                      <Linkify
                        // To open anchor tags in a new tab workaround due to library limitation
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key
                        ) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={decoratedHref}
                            key={key}
                          >
                            {decoratedText}
                          </a>
                        )}
                      >
                        {message.message}
                      </Linkify>
                    </MessageText>
                    <MessageTextCreated variant="caption" component="span">
                      {formatDate(message.createdAt, "h:mm a, D/MM/YYYY")}
                    </MessageTextCreated>
                  </MessageContent>
                </MessageWrapper>
              </MessageContainer>
            );
          })
        )}
        {/* For scroll into view */}
        <div ref={lastMessageRef}></div>
      </MessagesContainer>
      {props.currentChat.customType == "2" && (
        <SendMessageContainer>
          <MessageInput
            fullWidth={false}
            id="outlined-basic"
            hiddenLabel
            variant="outlined"
            multiline
            maxRows={2}
            value={messageValue}
            onChange={onMessageChange}
            onKeyDown={onKeyDown}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              style: {
                paddingLeft: "5px",
              },
            }}
          />
          <SendButton onClick={onTriggerSendMessage}>
            <SendIconStyled />
          </SendButton>
        </SendMessageContainer>
      )}
    </ChatRoomContainer>
  );
});

const mapStateToProps = (state) => {
  return {
    currentChat: state.user.currentChat,
    chatMessages: state.user.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChatMessages: bindActionCreators(fetchChatMessages, dispatch),
    sendMessage: bindActionCreators(sendMessage, dispatch),
    countUnreadMessage: bindActionCreators(countUnreadMessage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChatRoom);
