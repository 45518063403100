import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChatWrapper from "../../../components/chat/ChatWrapper/ChatWrapper";
import { getUser } from "../../../redux/actions/user_action";

const SharedChat = (props) => {
  const user = getUser();
  const { tag } = props;
  const [values, setValues] = useState({ width: null, height: null });
  const { width } = values;

  // USE CASE:
  // - if there is a need to open chat popup automatically
  // but auto-login is not yet complete, after login complete, chatbox will still not render
  // causing the chat popup to not open at all.
  // FIX:
  // listen to redux user object update, which can be used to re-render returned component
  //
  // NOTE:
  // redux hydration is done in Navbar.jsx, handleRecallGetUser() function.
  const reduxUserObj = useSelector((state) => state?.user?.user) || {};

  useEffect(() => {
    if (width == null) {
      setValues({
        ...values,
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", windowOnResizeListener);

    return () => {
      window.removeEventListener("resize", windowOnResizeListener);
    };
  }, []);

  const windowOnResizeListener = () => {
    setValues({
      ...values,
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  /* Don't render chat on mobile single job page */
  return (
    !(tag == "single-job-page" && width < 768) &&
    Object.keys(reduxUserObj).length > 0 && <ChatWrapper />
  );
};

export default SharedChat;
