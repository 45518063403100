import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const OpenChatContainer = styled(Grid)`
  && {
    height: 320px;
    width: 100%;
    overflow: auto;
  }
`;

export const GridProgress = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const GridStyled = styled(Grid)`
  && {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RetryContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const LabelStyled = styled(Grid)`
  && {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    margin-bottom: 10px;
  }
`;
