import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { SHOW_CHAT } from "../../../helpers/constant";
import { handleSwitchCountry } from "../../../helpers/link_management";
import { getUser, updateCurrentChat } from "../../../redux/actions/user_action";
import SendBird from "../../../utils/sendbird";
import ChatRoom from "../ChatRoom/ChatRoom";
import MessageFab from "../MessageFab/MessageFab";
import OpenChats from "../OpenChats/OpenChats";
import {
  ChatContainer,
  ChatContentWrapper,
  ChatHeader,
  CountryButton,
  GridChat,
  GridStyled,
  HeaderText,
  IconButtonStyled,
  MYCountryFlag,
  SGCountryFlag,
  SearchFieldContainer,
  SearchTextField,
} from "./styles";

let connectSendBirdCount = 5; // Disable manual reconnection on failure
let idleTimer;
let retryTimer;
let visibilityChange = null;
let hidden = null;
const connectSendBirdTries = 3; // Sendbird has own reconnect feature
const sb = new SendBird(process.env.SENDBIRD_APP_ID);

function ChatWrapper(props) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [showChatBox, setShowChatBox] = useState(false);
  const [sbConnected, setSbConnected] = useState(false);
  const [sbConnectionFailed, setSbConnectionFailed] = useState(false);
  const [isSingleChat, setIsSingleChat] = useState(false);
  const [doneLoadingOpenChats, setDoneLoadingOpenChats] = useState(false);
  const [poorNetwork, setPoorNetwork] = useState(false);
  const openChatsRef = useRef();
  const chatRoomRef = useRef();

  useEffect(() => {
    connectSendBird();

    // Setting idle timer
    resetIdleTimer();

    initVisibilityChange();

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const { query } = router;

    handleAutoOpenChat(window.location.href);

    // Open chat box if chatChannel param is present
    if (query.chatChannel) {
      setShowChatBox(true);
      setIsSingleChat(true);
    }
  }, [router.isReady]);

  const sbConnectedRef = useRef();
  const showChatBoxRef = useRef();

  function handleAutoOpenChat(url) {
    const urlObj = new URL(url);

    const urlSearchParamObj = new URLSearchParams(urlObj.search);

    const openChat = urlSearchParamObj.get(SHOW_CHAT);

    if (openChat?.toLowerCase().trim() === "true") toggleChatBox();
  }

  // So that visibility change callback able to retrieve correct state value
  sbConnectedRef.current = sbConnected;
  showChatBoxRef.current = showChatBox;

  function initVisibilityChange() {
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }

    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }

  function handleVisibilityChange() {
    if (document[hidden]) {
      onIdle(sbConnectedRef.current);
    } else {
      onActive(showChatBoxRef.current, sbConnectedRef.current, true);
    }
  }

  function connectSendBird(isChatRoom = false) {
    let user = getUser();

    if (user && user.id && sb) {
      if (sb.getConnectionStatus() !== "OPEN") {
        // Not yet connected to sendbird
        restartRetryTimer(); // While trying to connect to sendbird, start poor network timer
        sb.connect(user)
          .then((response) => {
            clearRetryTimer(); // Successfully connected to sendbird, clear poor network timer
            if (isChatRoom) {
              if (chatRoomRef?.current) {
                chatRoomRef.current.setSbConnected(true);
              }
            } else {
              setSbConnected(true);
            }

            if (sbConnectionFailed) {
              setSbConnectionFailed(false);
            }
          })
          .catch((error) => {
            clearRetryTimer(); // Failed to connected to sendbird, clear poor network timer, retry button will be shown via connectionFailed state/value
            if (!sbConnectionFailed) {
              setSbConnectionFailed(true);
            }
            if (connectSendBirdCount <= connectSendBirdTries) {
              // Try to connect to sendbird again
              connectSendBird();
            }
            connectSendBirdCount++;
          });
      } else {
        // Already connected
        if (isChatRoom) {
          if (chatRoomRef?.current) {
            chatRoomRef.current.setSbConnected(true);
          }
        } else {
          setSbConnected(true);
        }
        setSbConnectionFailed(false);
      }
    }
  }

  function disconnectSendBird() {
    if (sb) {
      sb.disconnect()
        .then((response) => {
          if (response) {
            clearRetryTimer();
            setSbConnected(false);
          }
        })
        .catch((error) => {
          console.error("Failed to disconnect sendbird");
        });
    }
  }

  function updateSendbirdConnectionCount(count) {
    connectSendBirdCount = count;
  }

  function updateSingleChatStatus(status) {
    dispatch(updateCurrentChat({}));
    setIsSingleChat(status);
  }

  // THIS IS THE CHATBOX TOGGLE, SET TO TRUE HERE FOR AUTO OPEN CHAT LIST
  function toggleChatBox() {
    setShowChatBox(!showChatBox);
  }

  function handleSearchChange(event) {
    setSearchValue(event.target.value);
  }

  // User activity management
  function resetIdleTimer() {
    if (idleTimer) {
      clearTimeout(idleTimer);
    }

    idleTimer = setTimeout(() => {
      onIdle(sbConnected);
    }, 30000);
  }

  function onActive(showChatBox, sbConnected, isFromFocus) {
    if (!showChatBox && !isFromFocus) return;

    resetIdleTimer();

    if (!sbConnected) {
      connectSendBird();

      if (openChatsRef?.current) {
        openChatsRef.current.createChannelHandler();
      }
    }
  }

  function onIdle(sbConnected) {
    setIsSingleChat(false);
    if (sbConnected) {
      disconnectSendBird();

      if (openChatsRef?.current) {
        openChatsRef.current.removeChannelHandler();
      }
    }
  }

  const onMouseMove = _.throttle(
    (e) => onActive(showChatBox, sbConnected, false),
    1000
  );

  function startRetryTimer(isChatRoom) {
    retryTimer = setTimeout(() => {
      if (isChatRoom) {
        if (chatRoomRef?.current) {
          if (!chatRoomRef.current.getChatRoomSbStatus()) {
            chatRoomRef.current.setPoorNetwork(true);
          }
        }
      } else {
        if (!sbConnectedRef.current) {
          setPoorNetwork(true);
        }
      }
    }, 8000);
  }

  function clearRetryTimer() {
    if (retryTimer) {
      clearTimeout(retryTimer);
    }
  }

  function restartRetryTimer(isChatRoom = false) {
    if (retryTimer) {
      clearTimeout(retryTimer);
    }
    startRetryTimer(isChatRoom);
  }

  return (
    <ChatContainer onMouseMove={onMouseMove}>
      <ChatContentWrapper $show_chatbox={showChatBox}>
        <GridChat>
          <ChatHeader>
            <HeaderText component="span">Messages</HeaderText>
            <IconButtonStyled onClick={toggleChatBox}>
              <CloseIcon />
            </IconButtonStyled>
          </ChatHeader>
          <SearchFieldContainer>
            <SearchTextField
              value={searchValue}
              size="small"
              label="Search chats"
              variant="outlined"
              onChange={handleSearchChange}
              inputProps={{
                "aria-label": "search chats",
              }}
            />

            <CountriesButtons />
          </SearchFieldContainer>

          <TabPanel value={value} index={0}>
            <OpenChats
              ref={openChatsRef}
              sb={sb}
              sbConnected={sbConnected}
              sbConnectionFailed={sbConnectionFailed}
              updateSingleChatStatus={updateSingleChatStatus}
              searchValue={searchValue}
              doneLoadingOpenChats={doneLoadingOpenChats}
              setDoneLoadingOpenChats={setDoneLoadingOpenChats}
              setPoorNetwork={setPoorNetwork}
              setSbConnected={setSbConnected}
              poorNetwork={poorNetwork}
              connectSendBird={connectSendBird}
              restartRetryTimer={restartRetryTimer}
              clearRetryTimer={clearRetryTimer}
              updateSendbirdConnectionCount={updateSendbirdConnectionCount}
            />
          </TabPanel>
        </GridChat>

        {isSingleChat ? (
          <ChatRoom
            ref={chatRoomRef}
            sb={sb}
            updateSingleChatStatus={updateSingleChatStatus}
            resetIdleTimer={resetIdleTimer}
            connectSendBird={connectSendBird}
            restartRetryTimer={restartRetryTimer}
            clearRetryTimer={clearRetryTimer}
            setPoorNetwork={setPoorNetwork}
            setSbConnected={setSbConnected}
            updateSendbirdConnectionCount={updateSendbirdConnectionCount}
          />
        ) : null}
      </ChatContentWrapper>
      <MessageFab toggleChatBox={toggleChatBox} showChatBox={showChatBox} />
    </ChatContainer>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Grid>
  );
}

function CountriesButtons() {
  const router = useRouter();

  // if same country, do nothing
  // if not, call helper function handleSwitchCountry
  function handleCountryClick(country) {
    if (country === process.env.NEXT_PUBLIC_JSW_GEOLOCATION) {
      return;
    } else {
      handleSwitchCountry({
        country: country,
        router: router,
        openNewTab: true,
      });
    }
  }

  return (
    <GridStyled
      container
      justifyContent={"center"}
      alignItems={"center"}
      flexWrap={"nowrap"}
      columnGap={"5px"}
      padding={"8px 0px 0px"}
    >
      <CountryButton
        selected={process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"}
        variant="outlined"
        onClick={() => handleCountryClick("my")}
      >
        <MYCountryFlag />
        Malaysia
      </CountryButton>
      <CountryButton
        selected={process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"}
        variant="outlined"
        onClick={() => handleCountryClick("sg")}
      >
        <SGCountryFlag />
        Singapore
      </CountryButton>
    </GridStyled>
  );
}

export default ChatWrapper;
