import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SendIcon from "@mui/icons-material/Send";
import WarningIcon from "@mui/icons-material/Warning";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

// Icon Button Component //
export const SendButton = styled(IconButton)`
  && {
    background-color: ${Color.hiredlyPurple}
    color: ${Color.white};
  }
`;

// Icon Components //
export const ArrowBackIosStyled = styled(ArrowBackIosIcon)`
  && {
    height: 18px;
    width: 18px;
    color: ${Color.white};
  }
`;

export const SendIconStyled = styled(SendIcon)`
  && {
    color: ${Color.hiredlyPurple};
  }
`;

export const WarningIconStyled = styled(WarningIcon)`
  && {
    height: 25px;
    width: 25px;
    margin: 0 0 0 20px;
    color: ${Color.accentYellow};
  }
`;

// Grid Components //
export const ChatRoomContainer = styled(Grid)`
  && {
    border-radius: 1rem;
    height: 100%;
    width: 360px;
    display: flex;
    flex-flow: column;
    position: absolute;
    z-index: 3;
    top: 0;
    background-color: ${Color.white};
  }
`;

export const ChatRoomHeaderContainer = styled(Grid)`
  && {
    border-radius: 1rem 1rem 0 0;
    width: 100%;
    flex: 0 1 auto;
    padding: 5px 10px 5px 10px;
    background-color: ${Color.hiredlyPurple};
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const AiviSessionContainer = styled(Grid)`
  && {
    width: 100%;
    flex: 0 1 auto;
    padding: 10px 10px 10px 10px;
    background-color: ${Color.black};
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const GridProgress = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const MessagesContainer = styled(Grid)`
  && {
    padding: 10px 5px 0 5px;
    flex: 1 1 auto;
    overflow: auto;
  }
`;

export const SendMessageContainer = styled(Grid)`
  && {
    max-height: 80px;
    width: 100%;
    flex: 0 1 auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid lightgrey;
    padding: 10px 10px 10px 10px;
  }
`;

export const RetryContainer = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

export const LabelStyled = styled(Grid)`
  && {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    margin-bottom: 10px;
  }
`;

// Typography Components //
export const PrimaryText = styled(Typography)`
  && {
    font-weight: bold;
    font-size: ${FontSize.desktopBody};
  }
`;

export const SecondaryText = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
  }
`;

export const MessageText = styled(Typography)`
  && {
    font-size: 1rem;
    color: ${Color.white};
    margin-bottom: 0.5rem;

    & a {
      color: ${Color.white};
    }
  }
`;

export const MessageTextCreated = styled(Typography)`
  && {
    color: ${Color.white};
    font-style: italic;
    text-align: right;
  }
`;

// Textfield Component //
export const MessageInput = styled(TextField)`
  && {
    width: 80%;
    & .MuiOutlinedInput-multiline {
      padding: 5px 0 !important;
    }
  }
`;

// Div components //
export const AiviDisclaimerTextContainer = styled.div`
  && {
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    color: ${Color.accentYellow};
  }
`;

export const TextContainer = styled.div`
  && {
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    color: ${Color.white};
  }
`;

export const MessageContainer = styled.div`
  && {
    display: list-item;
  }
`;

export const MessageWrapper = styled.div`
  && {
    margin: 0 0 10px 0;
    color: ${Color.white};
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) =>
      props.$isUserMessage === true ? "flex-end" : "flex-start"};
  }
`;

export const MessageContent = styled.div`
  && {
    border-radius: 0.75rem;
    padding: 0.5rem;
    flex: 0 1 auto;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: ${(props) =>
      props.$isUserMessage ? `${Color.hiredlyPurple}` : "black"};
  }
`;
