import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

// Typography Components //
export const PrimaryText = styled(Typography)`
  && {
    font-weight: bold;
    font-size: ${FontSize.desktopBody};
  }
`;
export const SecondaryText = styled(Typography)`
  && {
    font-size: ${FontSize.desktopCaption};
  }
`;

// Misc. Components //
export const ChatRowContainer = styled.div`
  && {
    padding: 0 10px 0 10px;
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${Color.white};
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
    align-items: center;
  }
`;
export const AvatarContainer = styled.div`
  && {
    width: 35px;
    height: 35px;
    position: relative;
  }
`;
export const AvatarStyled = styled(Avatar)`
  && {
    width: 35px;
    height: 35px;
  }
`;
export const TextContainer = styled.div`
  && {
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }
`;
export const CountBadgeContainer = styled.div`
  && {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 1px 6.1px 1px 6.1px;
    border-radius: 50px;
    background-color: ${Color.error};
    font-size: ${FontSize.badge};
    color: ${Color.white};
  }
`;
