import CircularProgress from "@mui/material/CircularProgress";
import { Fragment, forwardRef, useEffect, useImperativeHandle } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constants from "../../../helpers/constant";
import { getFilteredChats } from "../../../helpers/data_management";
import {
  addNewMessage,
  countUnreadMessage,
  fetchChatList,
  replaceChat,
  updateCurrentChat,
} from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import Button from "../../shared/SharedButton/SharedButton";
import ChatRow from "../ChatRow/ChatRow";
import {
  GridProgress,
  GridStyled,
  LabelStyled,
  OpenChatContainer,
  RetryContainer,
} from "./styles";

const OpenChats = forwardRef((props, ref) => {
  // Only fetch chat listing when sendbird is connected
  useEffect(() => {
    if (store.getState().user.openChats.length > 0) {
      return;
    }

    createChannelHandler();

    if (props.sbConnected) {
      getChatListing(true);
    }
  }, [props.sbConnected]);

  useEffect(() => {
    return () => {
      removeChannelHandler();
    };
  }, []);

  function createChannelHandler() {
    let params = {
      uniqueId: constants.CHAT_LIST_CHANNEL_HANDLER_ID,
      updateMessageList: updateMessageList,
      updateChannel: updatingChatFromList,
      countUnreadMessage: props.countUnreadMessage,
      updateCurrentChat: props.updateCurrentChat,
    };
    props.sb.createChannelListHandler(params);
  }

  function removeChannelHandler() {
    props.sb.removeChannelHandler(constants.CHAT_LIST_CHANNEL_HANDLER_ID);
  }

  // To allow parent to call this function
  useImperativeHandle(ref, () => ({
    createChannelHandler,
    removeChannelHandler,
  }));

  function updateMessageList(channel, message) {
    if (store.getState().user.currentChat?.url == channel.url) {
      props.addNewMessage(message);
      props.sb.markAsRead({ channel });
      updatingChatFromList(channel, false);
    } else {
      updatingChatFromList(channel, true);
    }
  }

  function onChatClicked(chat) {
    props.updateSingleChatStatus(true);
    props.updateCurrentChat(chat);
    store.getState().user.currentChatUrl = chat.url;
  }

  function updatingChatFromList(channel, recount) {
    let params = {
      channelUrl: channel.url,
      channel: channel,
      recountUnreadMessage: recount,
    };
    props.replaceChat(params);
  }

  function getChatListing(refresh = false) {
    let params = {
      sb: props.sb,
      customType: ["2"],
      refresh: refresh,
    };
    props.restartRetryTimer(); // While fetching chat listing, start poor network timer
    props
      .fetchChatList(params)
      .then(() => {
        // Basically can clear it anyway
        props.clearRetryTimer(); // Successfully fetch chat listing, clear poor network timer

        props.setDoneLoadingOpenChats(true);
      })
      .catch(() => {
        props.clearRetryTimer();
        props.setDoneLoadingOpenChats(true);
      });
  }

  function retrySendbird() {
    // Need set poor network back to false
    props.setPoorNetwork(false);
    props.setSbConnected(false);
    props.updateSendbirdConnectionCount(0);
    props.connectSendBird();
  }

  return (
    <OpenChatContainer>
      {!props.sbConnected || props.poorNetwork || props.fetchingOpenChat ? (
        <Fragment>
          {props.poorNetwork ? (
            <RetryContainer>
              <LabelStyled>
                Poor network connection, please click on the button to retry.
              </LabelStyled>
              <Button button_type={"SolidGrey"} onClick={retrySendbird}>
                Retry
              </Button>
            </RetryContainer>
          ) : (
            <GridProgress>
              <CircularProgress />
            </GridProgress>
          )}
        </Fragment>
      ) : (props.doneLoadingOpenChats &&
          props.searchValue == "" &&
          getFilteredChats(props.openChats, props.searchValue).length == 0) ||
        props.sbConnectionFailed ? (
        <GridStyled>
          {props.sbConnectionFailed
            ? "Failed to connect to chat server."
            : "You don't have any messages yet!"}
        </GridStyled>
      ) : (
        getFilteredChats(props.openChats, props.searchValue).map(
          (chat, index) => {
            return (
              <ChatRow
                key={index}
                sb={props.sb}
                chat={chat}
                onChatClicked={onChatClicked}
              />
            );
          }
        )
      )}
    </OpenChatContainer>
  );
});

const mapStateToProps = (state) => {
  return {
    openChats: state.user.openChats,
    fetchingOpenChat: state.user.fetchingOpenChat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChatList: bindActionCreators(fetchChatList, dispatch),
    updateCurrentChat: bindActionCreators(updateCurrentChat, dispatch),
    replaceChat: bindActionCreators(replaceChat, dispatch),
    addNewMessage: bindActionCreators(addNewMessage, dispatch),
    countUnreadMessage: bindActionCreators(countUnreadMessage, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(OpenChats);
