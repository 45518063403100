import { FontSize } from "../../../assets/font";
import Color from "../../../assets/colors";
import styled from "styled-components";
import { styled as styling } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MessageIcon from "@mui/icons-material/Message";
import CloseIcon from "@mui/icons-material/Close";

export const FabContainer = styled(Box)`
  && {
    //
  }
`;

export const FabMessage = styling(Fab)({
  backgroundColor: Color.hiredlyPurple,

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
  },
});

export const MessageIconStyled = styled(MessageIcon)`
  && {
    color: ${Color.white};
  }
`;

export const CloseIconStyled = styled(CloseIcon)`
  && {
    color: ${Color.white};
  }
`;

export const UnreadCount = styled(Typography)`
  && {
    color: ${Color.white};
    font-size: 0.75rem;
    font-weight: 700;
  }
`;

export const CountBadgeContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1051;
    top: -24%;
    right: -10%;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: ${Color.error};
  }
`;
